
/* color: #E0F7FA; */
/* color: #FF3EA5FF;
color: #EDFF00FF;
color: #00A4CCFF; */
/* color: #FE0000FF; */
/* color: #95DBE5FF; */
/* background-color:#5EE4F7; */
/* color: #ff8c42; */ 
/* #fb743e; 
#fc8621; */


/* MAIN SETTINGS  */
body{
  margin: 0;
  padding: 0;
  min-height: 100vh; 
}

.home-page,
.contact-page,
.about-page,
.product-page,
.gallery-page,
.service-page,
.view-page,
.footer,
nav {
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }


.footer {
  height: 18px !important; 
}


.addresscolor {
  color: black;
  font-size: 12px;
}

.copyrightowner {
  font-size: 11px; 
  font-family: 'UnifrakturMaguntia', cursive;
}

.logo {
  margin-left: 5px;
  margin-right: 5px;
}

.brand {
  letter-spacing: 1.5px;
  font-weight: 500;
  color: #FF3EA5FF;
}

/* NAVBAR  */
.nav-link:hover {
  color: red;
}

Navbar:hover {
  color: red;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

/* JUMBO  */
.jumbo {
  letter-spacing: 2px;
  color: #2BAE66FF;
}


/* CAROUSEL  */
.caption h3,
.caption p {
  color: black; 
}

.caption {
  background-color: red;
}

.carousel-img {
  width: 100%; 
  height: 1000px !important;
  background-color: red;
}

.d-block {
  border-radius: 5px;
}


/* CARD  */
.g-card {
  margin: 10px;
  padding: 10px;
}

.g-card-image {
  height: 300px; 
  max-width: 100%;
  width: 250px;
  object-fit: cover;
}

.g-card-title {
  font-size: 13px;
  margin: 0px;
}

.g-card-info {
  margin-top: 10px;
  min-height: 100px;
}

/* HOME-PAGE  */
.text-home {
  font-family: 'Nothing You Could Do', cursive;
}

.enjoy-section {
  background-color: #FF3EA5FF;
  margin-top: 100px;
}

.shelf {
  animation: 3s ease-in 1s alternate-reverse both infinite slidein;
  animation-direction: inherit;
  animation-direction: initial;
  animation-direction: unset;
}

@keyframes slidein {
  from {
    margin-left: -50%;
    width: 100%;
  }

  to {
    margin-left: 60%;
    width: 100%;
  }
} 

.homeImage:hover,
.aboutImagefirst:hover,
.contactImage:hover {
  position:relative;
  top:-25px;
  left:-35px;
  width:500px;
  height:auto;
  display:block;
  z-index:999;
}

.homeImage:hover,
.aboutImagefirst:hover,
.contactImage:hover {
  transform: scale(1.3);
  box-shadow: 0 8px 6px -6px rgb(61, 59, 59);
}


.thumbnail:hover {
  position:relative;
  top:-25px;
  left:-35px;
  width:500px;
  height:auto;
  display:block;
  z-index:999;
}

.thumbnail:hover {
  transform: scale(1.3);
}

/* ABOUT-PAGE  */
.text-about {
  font-size: 18px;
  color: #57A773;
}

.aboutImage,
.galleryImage
 {
  max-width: 100%;
  height: auto;
}

.aboutImage:hover {
  position:relative;
  top:-25px;
  left:-35px;
  width:500px;
  height:auto;
  display:block;
  z-index:999;
}

.aboutImage:hover {
  transform: scale(1.2);
}

.textprice {
  color: black; 
  font-size: 17px; 
  letter-spacing: 1px;
  font-family: 'Nothing You Could Do', cursive;
}

/* CONTACT-PAGE  */
.buttoncontact {
  background-color: black;
}

.success-msg {
  color: #57A773;
  padding: 10px 15px;
}

.err-msg {
  color: #EE6352;
  padding: 10px 15px;
}

/* SERVICES-PAGE  */
.service-page {
  font-size: 16px;
  color: #57A773;
}

/* PRODUCTS-PAGE  */
.cardholder {
  font-size: 20px;
  font-family: 'Nothing You Could Do', cursive;
  background-color: #fff;
}

.productsByCat {
  background-color: #57A773;
}

.productsByCat h3 {
  color: #FF3EA5FF; 
}

.productsImage {
  margin-left: 100px;
  margin-right: 100px;
}

.imagesOfProducts {                
  width: 250px;
  height: 300px;
  object-fit: cover;
  max-width: 100%;
  border: 20px solid black;
}
/* GALLERY-PAGE  */
.productimage {
  min-width: 90%;
  height: 400px;
}

.imagesOfProducts_p {
  font-size: 20px;
  font-family: 'Nothing You Could Do', cursive;
}

.shadowImage {
  box-shadow: 0 8px 6px -6px rgb(61, 59, 59);
}

/* MODAL  */
.modal {
  font-family: 'Montserrat', sans-serif;
}

.modal_title {
  font-size: 18px;
  font-weight: bold;
  color: #57A773;
}

.modal-header {
  border-bottom: 1px solid #fff;
}